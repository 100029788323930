interface UnitArrowUpIconProps {
  className?: string;
}

const UnitArrowUpIcon: React.FC<UnitArrowUpIconProps> = (props) => {
  return (
    <>
      <svg
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path d="M9 5.75L5 2.25L1 5.75" stroke="currentColor" strokeWidth="2" />
      </svg>
    </>
  );
};

export default UnitArrowUpIcon;
