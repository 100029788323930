interface ReOrderIconProps {
  className?: string;
}

const ReOrderIcon: React.FC<ReOrderIconProps> = (props) => {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          d="M8.00002 3.99935V5.99935L10.6667 3.33268L8.00002 0.666016V2.66602C5.05335 2.66602 2.66669 5.05268 2.66669 7.99935C2.66669 9.04601 2.97335 10.0193 3.49335 10.8393L4.46669 9.86602C4.16669 9.31268 4.00002 8.67268 4.00002 7.99935C4.00002 5.79268 5.79335 3.99935 8.00002 3.99935ZM12.5067 5.15935L11.5334 6.13268C11.8267 6.69268 12 7.32602 12 7.99935C12 10.206 10.2067 11.9993 8.00002 11.9993V9.99935L5.33335 12.666L8.00002 15.3327V13.3327C10.9467 13.3327 13.3334 10.946 13.3334 7.99935C13.3334 6.95268 13.0267 5.97935 12.5067 5.15935Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default ReOrderIcon;
