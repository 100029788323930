interface MenuItemArrowRightProps {
  className?: string;
}

const MenuItemArrowRight: React.FC<MenuItemArrowRightProps> = (props) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          d="M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default MenuItemArrowRight;
