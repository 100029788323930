interface FrontendConfig {
  shopNowUrlSlug: string;
  recipesUrlSlug: string;
  productUrlSlug: string;
  zendeskKey?: string;
}

const frontendConfig: FrontendConfig = {
  // this page is the main product listing page and is used as a logic/base to build the category and product url used in many CTAs. Whenever the client changes the main product listing page url slug this setting should be updated.
  shopNowUrlSlug: '/buy-fish-and-seafood',
  productUrlSlug: '/product',
  recipesUrlSlug: '/fish-and-seafood-recipes',
  zendeskKey: process.env.NEXT_PUBLIC_ZENDESK_KEY || null,
};

export default frontendConfig;
