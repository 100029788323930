interface CloseModalIconProps {
  className?: string;
}

const CloseModalIcon: React.FC<CloseModalIconProps> = (props) => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path d="M27 13L13 27M27 27L13 13" stroke="currentColor" strokeWidth="2" />
        <rect x="1" y="1" width="38" height="38" rx="19" stroke="currentColor" strokeWidth="2" />
      </svg>
    </>
  );
};

export default CloseModalIcon;
