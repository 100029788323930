import React from 'react';
import { useZendeskStore } from 'store/zendeskStore';
import classnames from 'utils/classnames';

type ZendeskLinkProps = {
  className?: string;
  children?: React.ReactNode;
};

const ZendeskLink: React.FC<ZendeskLinkProps> = (props) => {
  const zendesk = useZendeskStore();
  const className = classnames(`cursor-pointer`, props.className);

  const onClick = () => {
    if (!zendesk.isReady || zendesk.isOpen) return;
    zendesk.setOpen(true);
  };

  return (
    <div className={className} onClick={onClick}>
      {props.children}
    </div>
  );
};

export default ZendeskLink;
