interface InfoIconProps {
  className?: string;
}

const InfoIcon: React.FC<InfoIconProps> = (props) => {
  return (
    <>
      <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <circle cx="16" cy="16.5" r="11" stroke="currentColor" strokeWidth="2" />
        <path d="M16 14.5L16 22.5" stroke="currentColor" strokeWidth="2" />
        <path d="M16 10.5L16 12.5" stroke="currentColor" strokeWidth="2" />
      </svg>
    </>
  );
};

export default InfoIcon;
