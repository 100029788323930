import create from 'zustand';

interface AppState {
  isReady: boolean;
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  setReady: () => void;
}

export const useZendeskStore = create<AppState>((set) => ({
  isReady: false,
  isOpen: false,
  setOpen: (value: boolean) => {
    set(() => ({
      isOpen: value,
    }));
  },
  setReady: () => {
    set(() => ({
      isReady: true,
    }));
  },
}));
