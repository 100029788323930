interface UnitArrowDownIconProps {
  className?: string;
}

const UnitArrowDownIcon: React.FC<UnitArrowDownIconProps> = (props) => {
  return (
    <>
      <svg
        width="10"
        height="7"
        viewBox="0 0 10 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path d="M1 1L5 5L9 1" stroke="currentColor" strokeWidth="2" />
      </svg>
    </>
  );
};

export default UnitArrowDownIcon;
