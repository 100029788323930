import { LinkData, NavigationItemData } from 'lib/contentful/types';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import classnames from 'utils/classnames';
import { categoryLink, recipeLink } from 'utils/links';
import ZendeskLink from './zendesk/ZendeskLink';
import React from 'react';

type LinkProps = Partial<LinkData> & {
  className?: string;
  activeClassName?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const resolveInternalPageUrl = (internalPage: LinkProps['internalPage']) => {
  switch (internalPage.__typename) {
    case 'Page': {
      return internalPage.url === '/' ? '/' : `/${internalPage.url}`;
    }

    // //@todo Contentful doesn't have the id/slug and only SKU due big commerce extension.
    // case 'Product': {
    //   return `/product/${internalPage.sku}`;
    // }

    case 'ProductCategory': {
      return categoryLink(internalPage.id);
    }

    case 'Recipe': {
      return recipeLink(internalPage.slug);
    }

    default: {
      return '';
    }
  }
};

export const resolveNavigationPageUrl = (currentNavigationItem: NavigationItemData) => {
  const href = currentNavigationItem?.link?.internalPage
    ? resolveInternalPageUrl(currentNavigationItem?.link?.internalPage)
    : currentNavigationItem?.link?.externalUrl || '/';

  return { href };
};

/**
 * Generic Link
 * @param props
 * @returns
 */
const Link: React.FC<LinkProps> = (props) => {
  const route = useRouter();
  const {
    openInANewTab,
    internalUrl,
    className,
    activeClassName,
    externalUrl,
    label,
    children,
    internalPage,
    customAction,
  } = props;
  const target = openInANewTab ? '_blank' : '_self';
  const href = internalUrl
    ? internalUrl
    : internalPage
    ? resolveInternalPageUrl(internalPage)
    : externalUrl;
  const activeClass = route.asPath === href && activeClassName ? activeClassName : '';
  const linkClass = classnames(className, activeClass);

  if (customAction === 'zendesk-chat') {
    return (
      <ZendeskLink className={`${linkClass}`}>
        {label && label} {children && children}
      </ZendeskLink>
    );
  }

  if (internalPage || internalUrl) {
    const url = internalUrl || resolveInternalPageUrl(internalPage);
    return (
      <NextLink href={url} passHref>
        <a
          target={target}
          className={`${linkClass}`}
          onClick={() => {
            if (props.onClick) {
              props.onClick();
            }
          }}
        >
          {label && label} {children && children}
        </a>
      </NextLink>
    );
  }

  return (
    <a
      href={externalUrl}
      target={target}
      className={linkClass}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {children && children}
      {label && label}
    </a>
  );
};
export default Link;
