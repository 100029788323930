import React, { ChangeEvent } from 'react';
import classnames from '../../utils/classnames';
import classames from '../../utils/classnames';
import InfoIcon from 'components/icons/InfoIcon';
import UnitArrowDownIcon from 'components/icons/UnitArrowDownIcon';

interface Props {
  id: string;
  label: string;
  value: string | number;
  onBlur: (input: ChangeEvent<HTMLSelectElement>) => any;
  onChange?: (input: ChangeEvent<HTMLSelectElement>) => any;

  error?: string;
  autoComplete?: string;
  className?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  variant?: string;

  children?: React.ReactNode;
}

const rootClasses = {
  root: 'peer w-full rounded  appearance-none  px-4 pb-3.5 pt-7  placeholder-transparent  focus:outline-none w-full text-gray-900 peer px-4 pt-8 pb-2 placeholder-transparent text-lg h-16',
};

const variantClasses = {
  standard:
    'rounded bg-gray-100 border border-sf-blue-700 border-border-base focus:shadow focus:bg-light focus:border-accent border border-opacity-20 focus:border-opacity-30 focus:outline-none',
  flushed:
    'bg-transparent border-b-[1px] border-sf-blue-500 text-sf-blue-400 focus:border-b-2 active:border-b-2 border-opacity-60',
};

const errorClasses = {
  standard: 'border-solid border-rose-600 border-opacity-95',
  flushed: 'border-solid border-sf-blue-700 border-b-2 border-opacity-95',
};

const Input: React.FC<Props> = (props) => {
  const {
    id,
    error,
    label,
    onBlur,
    onChange,
    value,
    autoComplete,
    required,
    disabled,
    readOnly,
    className,
    variant = 'standard',
  } = props;
  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);

  const placeholderShown = !(focused || value);

  return (
    <>
      <div className="relative">
        <select
          id={id}
          name={id}
          onChange={onChange}
          value={value}
          onFocus={onFocus}
          onBlur={(e) => {
            onBlur(e);
            setFocused(false);
          }}
          autoComplete={autoComplete}
          required={required}
          disabled={disabled}
          className={classames(
            className,
            rootClasses.root,
            {
              [variantClasses.standard]: variant === 'standard',
              [errorClasses.standard]: !!error && variant === 'standard',
              'cursor-not-allowed text-opacity-50': readOnly || disabled,
            },
            {
              [variantClasses.flushed]: variant === 'flushed',
              [errorClasses.flushed]: !!error && variant === 'flushed',
            }
          )}
        >
          {props.children}
        </select>
        <div className="absolute right-3  top-0 pt-8">
          <UnitArrowDownIcon />
        </div>

        {label && (
          <label
            htmlFor={id}
            className={classnames(
              'absolute left-4 top-2 text-sm text-sf-blue-700 transition-all ',
              {
                'top-6 text-base text-opacity-60': placeholderShown,
                'top-2 text-sm  ': value || !placeholderShown,
              }
            )}
            suppressHydrationWarning
          >
            {label}
          </label>
        )}

        {error && (
          <>
            <div
              className={classnames('mt-1 flex items-center text-left text-xs', {
                'text-rose-600': variant === 'standard',
                'text-sf-blue-700': variant === 'flushed',
              })}
            >
              <InfoIcon className="h-[13.3px]" />
              <div>{error}</div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Input;
