import frontConfig from 'config/frontend-config';

export const productLink = (bigCommercePath: string) => {
  return `${frontConfig.productUrlSlug}${bigCommercePath}`;
};

export const recipeLink = (slug: string) => {
  return `${frontConfig.recipesUrlSlug}/${slug}`;
};

export const categoryLink = (categoryId: string) => {
  return `${frontConfig.shopNowUrlSlug}?category=${encodeURIComponent(categoryId)}`;
};
