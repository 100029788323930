interface SpinnerIconProps {
  className?: string;
}

const SpinnerIcon: React.FC<SpinnerIconProps> = (props) => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <mask id="path-1-inside-1_1147_35654" fill="white">
          <path d="M40 20C40 23.9556 38.827 27.8224 36.6294 31.1114C34.4318 34.4004 31.3082 36.9638 27.6537 38.4776C23.9991 39.9913 19.9778 40.3874 16.0982 39.6157C12.2186 38.844 8.65491 36.9392 5.85786 34.1421C3.06081 31.3451 1.156 27.7814 0.384294 23.9018C-0.38741 20.0222 0.00865672 16.0009 1.52241 12.3463C3.03616 8.69181 5.59961 5.56824 8.8886 3.37061C12.1776 1.17298 16.0444 -4.71704e-08 20 0L20 2.86143C16.6103 2.86143 13.2967 3.86659 10.4783 5.7498C7.6599 7.63301 5.4632 10.3097 4.16603 13.4414C2.86885 16.573 2.52945 20.019 3.19074 23.3436C3.85204 26.6681 5.48433 29.7219 7.8812 32.1188C10.2781 34.5157 13.3319 36.148 16.6564 36.8093C19.981 37.4706 23.427 37.1312 26.5586 35.834C29.6903 34.5368 32.367 32.3401 34.2502 29.5217C36.1334 26.7033 37.1386 23.3897 37.1386 20H40Z" />
        </mask>
        <path
          d="M40 20C40 23.9556 38.827 27.8224 36.6294 31.1114C34.4318 34.4004 31.3082 36.9638 27.6537 38.4776C23.9991 39.9913 19.9778 40.3874 16.0982 39.6157C12.2186 38.844 8.65491 36.9392 5.85786 34.1421C3.06081 31.3451 1.156 27.7814 0.384294 23.9018C-0.38741 20.0222 0.00865672 16.0009 1.52241 12.3463C3.03616 8.69181 5.59961 5.56824 8.8886 3.37061C12.1776 1.17298 16.0444 -4.71704e-08 20 0L20 2.86143C16.6103 2.86143 13.2967 3.86659 10.4783 5.7498C7.6599 7.63301 5.4632 10.3097 4.16603 13.4414C2.86885 16.573 2.52945 20.019 3.19074 23.3436C3.85204 26.6681 5.48433 29.7219 7.8812 32.1188C10.2781 34.5157 13.3319 36.148 16.6564 36.8093C19.981 37.4706 23.427 37.1312 26.5586 35.834C29.6903 34.5368 32.367 32.3401 34.2502 29.5217C36.1334 26.7033 37.1386 23.3897 37.1386 20H40Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="4"
          strokeMiterlimit="1.30541"
          mask="url(#path-1-inside-1_1147_35654)"
        />
      </svg>
    </>
  );
};

export default SpinnerIcon;
