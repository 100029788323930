const useLocalCurrent = false;

export const normalizePrice = (amount: string): string => {
  return parseFloat(amount).toFixed(2);
};

export const numberToPrice = (v: number): string => {
  return v?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const numberToCurrency = (v: number, currency: string): string => {
  const price = numberToPrice(v);

  return currency && useLocalCurrent ? `${currency}${price}` : `$${price}`; // 12,345.67;
};

export const stringToCurrency = (v: string, currency: string): string => {
  //convert string to number
  const value = Number(v).toFixed(2);
  return currency && useLocalCurrent ? `${currency}${value}` : `$${value}`; // 12,345.67;
};
