import classnames from 'utils/classnames';

interface HorizontalLineProps {
  className?: string;
}

const HorizontalLine: React.FC<HorizontalLineProps> = (props) => {
  return (
    <>
      <div className={classnames('relative h-[2px] w-full overflow-hidden', props.className)}>
        <div className="absolute inset-0 bg-sf-blue-700 opacity-5"></div>
      </div>
    </>
  );
};

export default HorizontalLine;
