/**
 * This should be alligned with big commerce IDS. As it won't change
 */

export const PurchaseType = {
  Subscription: {
    id: parseInt(process.env.NEXT_PUBLIC_BC_SUBSCRIPTION_VARIANT_ID), //prod-> id: 114,
    label: 'Subscription',
  },
  OneOff: {
    id: parseInt(process.env.NEXT_PUBLIC_BC_ONE_OFF_VARIANT_ID), //prod-> id: 113,
    label: 'One Off',
  },
};
