import { ButtonHTMLAttributes } from 'react';
import { LinkData } from 'lib/contentful/types';
import classnames from 'utils/classnames';
import Link from './Link';

type LinkControl = Partial<LinkData> & {
  href?: string;
};

type ButtonControl = {
  type?: 'button' | 'submit';
};

interface Control extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonSize?: 'normal' | 'small' | 'none';
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}

type OutlineControl = {
  color?: 'blue' | 'white';
};

type FillLinkProps = Control & LinkControl;

type OutlineLinkProps = Control & LinkControl & OutlineControl;

type FillButtonProps = Control &
  ButtonControl & {
    fullWidth?: boolean;
  };

type OutlineButtonProps = Control & ButtonControl & OutlineControl;

type FillStyleOptions = {
  buttonSize: string;
  disabled: boolean;
  fullWidth?: boolean;
  color?: string;
};

type OutlineStyleOptions = {
  disabled: boolean;
  color: string;
  buttonSize: string;
  fullWidth?: boolean;
};

const fillStyles = (options: FillStyleOptions) => {
  return classnames(
    'rounded-full uppercase bg-sf-blue-700 font-medium text-white hover:bg-sf-blue-500 cursor-pointer',
    //props.className,
    {
      'pointer-events-none opacity-30': options.disabled,
      'px-8 py-4': options.buttonSize === 'normal',
      'px-4 py-3 text-sm': options.buttonSize === 'small',
      'w-full block text-center': options.fullWidth,
      'inline-flex': !options.fullWidth,
      'bg-sf-blue-400': options.color === 'light-blue',
    }
  );
};

const outlineStyles = (options: OutlineStyleOptions) => {
  return classnames(
    'rounded-full border uppercase font-medium border-current bg-opacity-0 hover:bg-opacity-10 cursor-pointer',
    {
      'pointer-events-none opacity-30': options.disabled,
      'bg-white text-white': options.color === 'white',
      'bg-sf-blue-700 text-sf-blue-700': options.color === 'blue',
      'px-8 py-4': options.buttonSize === 'normal',
      'px-4 py-3 text-sm': options.buttonSize === 'small',
      'inline-flex': !options.fullWidth,
      'block w-full text-center': options.fullWidth,
    }
  );
};

const OutlineLinkButton: React.FC<OutlineLinkProps> = (props) => {
  const { disabled, buttonSize = 'normal', color = 'blue', fullWidth } = props;

  const style = outlineStyles({
    buttonSize: buttonSize,
    color: color,
    disabled: disabled,
    fullWidth: fullWidth,
  });

  return <Link {...props} className={style}></Link>;
};

const OutlineButton: React.FC<OutlineButtonProps> = (props) => {
  const { disabled, onClick, buttonSize = 'normal', color = 'blue', fullWidth } = props;
  const style = outlineStyles({
    color: color,
    disabled: disabled,
    buttonSize: buttonSize,
    fullWidth: fullWidth,
  });
  return (
    <button disabled={disabled} onClick={onClick} className={style}>
      {props.children}
    </button>
  );
};
const FillLinkButton: React.FC<FillLinkProps> = (props) => {
  const { disabled, buttonSize = 'normal', fullWidth } = props;
  const style = fillStyles({
    buttonSize: buttonSize,
    disabled: disabled,
    fullWidth: fullWidth,
  });
  return <Link {...props} className={style + ` ${props.className}`}></Link>;
};

const FillButton: React.FC<FillButtonProps> = (props) => {
  const { disabled, onClick, color, className, buttonSize = 'normal', fullWidth, ...rest } = props;
  const style = fillStyles({
    disabled: disabled,
    buttonSize: buttonSize,
    fullWidth: fullWidth,
    color: color,
  });
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classnames(style, className)}
      {...rest}
    >
      {props.children}
    </button>
  );
};

export { OutlineLinkButton, FillLinkButton, OutlineButton, FillButton };
