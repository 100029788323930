interface CheckboxIconProps {
  className?: string;
}

const CheckboxIcon: React.FC<CheckboxIconProps> = (props) => {
  return (
    <>
      <svg
        className={props.className}
        width="16"
        height="16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9737 5L7.43278 10.541C6.82074 11.153 5.82843 11.153 5.21639 10.541L3 8.32458"
          className="stroke-current"
        />
      </svg>
    </>
  );
};

export default CheckboxIcon;
