interface MenuItemArrowLeftProps {
  className?: string;
}

const MenuItemArrowLeft: React.FC<MenuItemArrowLeftProps> = (props) => {
  return (
    <>
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          d="M6.00016 0L7.41016 1.41L2.83016 6L7.41016 10.59L6.00016 12L0.000156403 6L6.00016 0Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default MenuItemArrowLeft;
