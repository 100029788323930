/**
 * This is the public api that will be consumed by the frontend.
 */

import axios from 'axios';
import { PurchaseType } from 'lib/bigcommerce/product-options';
import {
  BCCreateCartResult,
  BCShippingMethodsZoneResult,
  BCUpdateCartLineItemResult,
} from 'lib/bigcommerce/rest/operations';

import { CustomerAddressData, OrderData, ProductData } from 'lib/bigcommerce/types';
import { RecipeListingData } from 'lib/contentful/types';
import { DeliveryDatesOptions } from 'lib/shipping/shipping';
import { SFSubscription } from 'pages/api/account/subscriptions';
import { ContentSearchItem } from './search';

export enum SanfordAPIErrors {
  UnexpectedError = 'UnexpectedError',
  Unautheticated = 'Unauthenticated',
  CartExpired = 'CartExpired',
}

export type APIResponse<T = any> = {
  result: T;
  success: boolean;
  message: string;
  error?: {
    code: SanfordAPIErrors;
  };
};

export interface SFProccessOrderRequest {
  cartId: string;
  orderId: string;
}

export interface SFUpdateShippingOption {
  isSubscription: boolean;
  deliveryDate: string;
  frequency: string;
  cartId: string;
  customerId: string;
}

export interface SFShippingOptions {
  dates: Array<DeliveryDatesOptions>;
  frequency: Array<string>;
  isSubscription: boolean;
  selectedDate: string;
  selectedFrequency: string;
}

export interface SFSearchResult {
  products: Array<ProductData>;
  recipes: Array<RecipeListingData>;
  content: Array<ContentSearchItem>;
}

export interface SFCustomer {
  firstName: string;
  id: number;
  lastName: string;
  email: string;
}

export interface SFSignupRequest {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
  offersAccepted: boolean;
}

export interface SFLoginRequest {
  email: string;
  password: string;
}

export interface SFForgotPasswordRequest {
  email: string;
}

export interface SFGetCustomerDetailsResponse {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  dateOfBirth?: string;
  DOB?: {
    day?: number;
    month?: number;
    year?: number;
  };
}

export interface SFGetCustomerOrdersResponse {
  orders: Array<OrderData>;
}

export interface SFGetSubscriptionResponse {
  subscriptions: SFSubscription[];
}

export interface SFGetCustomerOrderResponse {
  order: OrderData;
}

export interface SFUpdateCustomerDetailsRequest {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  DOB?: {
    day?: number;
    month?: number;
    year?: number;
  };
}

export type SFUpdateSubscriptionRequest = {
  baseSubscription: SFSubscription;
  subscription: SFSubscription;
};

export type SFManageSubscriptionRequest = {
  action: string;
  data?: {
    alias?: string;
    deliveryDate?: string;
    subscriptionFrequency?: string;
  };
};

export type SFShippingAddressRequest = {
  subscription: SFSubscription;
  address: CustomerAddressData;
};

export interface SFGetCustomerAddressesResult {
  addresses: Array<CustomerAddressData>;
  pagination: {
    count: number;
    current_page: number;
    per_page: number;
    total: number;
    total_pages: number;
  };
}

export type SFGetCustomerAddressResult = CustomerAddressData;

export interface SFAddUpdateCustomerAddressRequest {
  firstName: string;
  lastName: string;
  company: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  countryCode: string;
  stateOrProvince: string;
  postalCode: string;
}

export interface SFUpdateCustomerPasswordRequest {
  password: string;
  newPassword: string;
  newPasswordRepeat: string;
}

export interface SFSetCartPurchaseType {
  purchaseTypeId: number;
}

export type SFUpdateCartItemRequest = {
  itemId: string;
  quantity: number;
  variantId: number;
  productId: number;
};

export type SFRemoveCartItemRequest = {
  itemId: string;
};

export type SFAddItemToCartRequest = {
  productId: number;
  variantId: number;
  quantity: number;
};

export type SFUpdateCartItemResult = BCUpdateCartLineItemResult;

export type SFCreateCartResult = BCCreateCartResult;

export interface SFCartItem {
  productId: number;
  variantId: number;
  purchaseType: typeof PurchaseType.OneOff | typeof PurchaseType.Subscription;
  id: string;
  discount: number;
  name: string;
  imageUrl: { src: string; fallback: string };
  totalPrice: number;
  quantity: number;
  price: number;
  sku: string;
  weightSummary: string;
}

export type SFCart = {
  id: string;
  //if purchaseTypeId === null means the card is invalid
  isSubscription: boolean;
  purchaseTypeId: number | null;
  currency: string;
  price: number;
  discount: number;
  lineItems: Array<SFCartItem>;
};

export type SFCheckoutInfo = {
  checkoutUrl: string;
  embededCheckoutUrl: string;
};

export type SFSubscribeRequest = {
  email: string;
  firstName: string;
};

export type SFValidateResetPasswordTokenResult = {
  valid: boolean;
};

export interface SFSubmitResetPasswordRequest {
  token: string;
  password: string;
  passwordRepeat: string;
}

export interface SFDatesResponse {
  dates: Array<{ date: string; description: string }>;
  frequency: Array<string>;
  selectedDate: string;
  selectedFrequency: string;
}

const instance = axios.create({ baseURL: '/api' });

const getCart = () => {
  return instance.get<APIResponse<SFCart>>('/cart');
};

const getCustomer = () => {
  return instance.get<APIResponse<SFCustomer>>('/customer');
};

const removeCartItem = (data: SFRemoveCartItemRequest) => {
  return instance.delete<APIResponse<unknown>>(`/cart/item/${data.itemId}`);
};

const updateCartItem = (data: SFUpdateCartItemRequest) => {
  return instance.put<APIResponse<unknown>>(`/cart/item/${data.itemId}`, {
    quantity: data.quantity,
    productId: data.productId,
  });
};

const getShippingOptions = () => {
  return instance.post<APIResponse<SFShippingOptions>>('/shipping/options');
};

const checkout = () => {
  return instance.post<APIResponse<SFCheckoutInfo>>('/checkout');
};

const addToCart = (data: SFAddItemToCartRequest) => {
  return instance.post<APIResponse<unknown>>('/cart/add', data);
};

const login = (data: SFLoginRequest) => {
  return instance.post<APIResponse<unknown>>('/login', data);
};

const logout = () => {
  return instance.post<APIResponse<unknown>>('/logout', {});
};

const forgotPassword = (data: SFForgotPasswordRequest) => {
  return instance.post<APIResponse<unknown>>('/forgot-password', data);
};

const getCustomerDetails = () => {
  return instance.get<APIResponse<SFGetCustomerDetailsResponse>>('/account/details');
};

const updateCustomerDetails = (data: SFUpdateCustomerDetailsRequest) => {
  return instance.put<APIResponse<unknown>>('/account/details', data);
};

const updateCustomerPassword = (data: SFUpdateCustomerPasswordRequest) => {
  return instance.post<APIResponse<unknown>>('/account/password', data);
};

const getCustomerOrders = () => {
  return instance.get<APIResponse<SFGetCustomerOrdersResponse>>('/account/orders');
};

const getCustomerOrder = (id: number) => {
  return instance.get<APIResponse<SFGetCustomerOrderResponse>>(`/account/orders/${id}`);
};

const getCustomerSubscriptions = () => {
  return instance.get<APIResponse<SFGetSubscriptionResponse>>('/account/subscriptions');
};

const getDates = (subscriptionId: string) => {
  return instance.get<APIResponse<SFDatesResponse>>(`/app/dates?subscription=${subscriptionId}`);
};

const updateCustomerSubscription = (id: number, data: SFUpdateSubscriptionRequest) => {
  return instance.put<APIResponse<unknown>>(`/account/subscriptions/${id}`, data);
};

const manageCustomerSubscription = (id: number, data: { action: string }) => {
  return instance.post<APIResponse<unknown>>(`/account/subscriptions/${id}`, data);
};

const getCustomerAddresses = () => {
  return instance.get<APIResponse<SFGetCustomerAddressesResult>>('/account/addresses');
};

const addCustomerAddress = (data: SFAddUpdateCustomerAddressRequest) => {
  return instance.post<APIResponse<unknown>>(`/account/addresses`, data);
};

const getCustomerAddress = (id: number) => {
  return instance.get<APIResponse<SFGetCustomerAddressResult>>(`/account/addresses/${id}`);
};

const updateCustomerAddress = (id: number, data: SFAddUpdateCustomerAddressRequest) => {
  return instance.put<APIResponse<unknown>>(`/account/addresses/${id}`, data);
};

const getCustomerShippingCost = (data: SFShippingAddressRequest) => {
  return instance.post<APIResponse<BCShippingMethodsZoneResult[0]>>(
    `/account/addresses/cost`,
    data
  );
};

const deleteCustomerAddress = (id: number) => {
  return instance.delete<APIResponse<unknown>>(`/account/addresses/${id}`);
};

const signup = (data: SFSignupRequest) => {
  return instance.post<APIResponse<unknown>>('/signup', data);
};

const setCartPurchaseType = (data: SFSetCartPurchaseType) => {
  return instance.post<APIResponse<unknown>>('/cart/set-purchase-type', data);
};

const subscribe = (data: SFSubscribeRequest) => {
  return instance.post<APIResponse<unknown>>('/subscribe', data);
};

const search = (query = '') => {
  return instance.get<APIResponse<SFSearchResult>>(`/search?query=${query}`);
};

const validateResetPasswordToken = (token: string) => {
  return instance.get<APIResponse<SFValidateResetPasswordTokenResult>>('/reset-password', {
    params: {
      token,
    },
  });
};

const submitResetPassword = (data: SFSubmitResetPasswordRequest) => {
  return instance.post<APIResponse<unknown>>('/reset-password', data);
};

const sanfordApi = {
  addCustomerAddress,
  addToCart,
  checkout,
  deleteCustomerAddress,
  forgotPassword,
  getCart,
  getCustomer,
  getCustomerAddress,
  getCustomerAddresses,
  getCustomerDetails,
  getCustomerOrder,
  getCustomerOrders,
  getCustomerSubscriptions,
  getDates,
  login,
  logout,
  manageCustomerSubscription,
  removeCartItem,
  search,
  setCartPurchaseType,
  signup,
  submitResetPassword,
  subscribe,
  updateCartItem,
  updateCustomerAddress,
  getCustomerShippingCost,
  updateCustomerDetails,
  updateCustomerPassword,
  updateCustomerSubscription,
  validateResetPasswordToken,
};

export default sanfordApi;
